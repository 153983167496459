import { useTranslation } from 'react-i18next';
import { Button as ChakraButton, useToast } from '@chakra-ui/react';
import {
  Space,
  Table,
  Form,
  Input,
  Modal,
  Cascader,
  Select,
  Button,
} from 'antd';
import axios from 'axios';
import {
  API_BASE_URL,
  EXAPAY_URL,
  EXAPAY_API_KEY,
  EXAPAY_RECEIVER_EMAIL,
  EXAPAY_RECEIVER_NAME,
  EXAPAY_TOKEN,
} from '../config';
import React, { useState, useEffect, useContext } from 'react';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { AuthContext } from '../../contexts/index.tsx';
import { getNetworkList } from '../exapay/GetNetworkList';
import TopUpButton from '../button/TopUpButton';

function ViewQuota() {
  const { userInfo } = useContext(AuthContext);
  const toast = useToast();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(true);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateId, setUpdateId] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [providerList, setProviderList] = useState([]);
  const [total, setTotal] = useState(0);
  const [adminUserList, setAdminUserList] = useState([]);
  const [networkList, setNetworkList] = useState([]);
  const [paymentNetwork, setPaymentNetwork] = useState([]);
  const [allowedCountryCodes, setAllowedCountryCodes] = useState([]);
  const [CountryCodes, setCountryCodes] = useState([]);

  const isSuperAdmin = userInfo.roleType === 'SUPER_ADMIN';

  const columns = [
    {
      title: 'username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'smsAmount',
      dataIndex: 'smsAmount',
      key: 'smsAmount',
    },
    {
      title: 'smsUsed',
      dataIndex: 'smsUsed',
      key: 'smsUsed',
    },
    {
      title: 'allowedCountryCodes',
      dataIndex: 'allowedCountryCodes',
    },
    {
      title: 'createdAt',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          {isSuperAdmin ? (
            <ChakraButton
              colorScheme="purple"
              type="submit"
              onClick={() => onEditButtonClick(record)}
            >
              Edit Account
            </ChakraButton>
          ) : (
            <TopUpButton
            // accountId={record.id}
            />
          )}
        </Space>
      ),
    },
  ].filter(n => n);

  const getDataSource = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${API_BASE_URL}/octopus/api/admin/account/list`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const list = data?.data?.list;
      console.log('data source: ', list);
      setDataSource(Array.isArray(list) ? list : []);
      setTotal(data?.data?.total || 0);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const getAllPrices = async (payload = {}) => {
    try {
      const { data } = await axios.get(
        `${API_BASE_URL}/octopus/api/admin/pricing/list`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const list = data?.data?.data;
      //   console.log(list);
      // setDataSource(Array.isArray(list) ? list : []);
      const retrievedData = Array.isArray(list) ? list : [];
      const updatedData = retrievedData.map((item, index) => ({
        value: item.countryCode,
        label: item.countryName,
      }));
      console.log(updatedData);
      updatedData.sort((a, b) => a.label.localeCompare(b.label));
      setCountryCodes(updatedData);
    } catch (err) {
    } finally {
    }
  };

  const handlePrice = async (selectedValue, index) => {
    const { data } = await axios.post(
      `${API_BASE_URL}/octopus/api/admin/pricing/getPrice`,
      { countryCode: selectedValue },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    const price = parseFloat(data.data.pricing).toFixed(3);

    const oldAllowedCountryCodes = form.getFieldValue('allowedCountryCodes');
    oldAllowedCountryCodes[index].price = price;
    console.log('price: ', oldAllowedCountryCodes);

    form.setFieldValue(
      'allowedCountryCodes',
      JSON.parse(JSON.stringify(oldAllowedCountryCodes))
    );
  };

  const getProviderList = async () => {
    if (userInfo.roleType !== 'SUPER_ADMIN') {
      return;
    }
    try {
      const { data } = await axios.get(
        `${API_BASE_URL}/octopus/api/admin/provider/list`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const list = data?.data?.list;
      let options = (Array.isArray(list) ? list : []).reduce((p, v) => {
        const i = p.findIndex(n => n.value === v.type);
        const item = { value: v.id, label: v.nickname || v.username };
        if (i !== -1) {
          p[i].children.push(item);
        } else {
          p.push({ label: v.type, value: v.type, children: [item] });
        }
        return p;
      }, []);
      setProviderList(options);
    } catch (err) {
    } finally {
    }
  };

  const getAdminUserList = async () => {
    if (userInfo.roleType !== 'SUPER_ADMIN') {
      return;
    }
    try {
      const { data } = await axios.get(
        `${API_BASE_URL}/octopus/api/admin/user/list`,
        {
          headers: { Authorization: `Bearer ${token}` },
          params: { pageNo: 1, pageSize: 9999 },
        }
      );
      const list = data?.data?.list;
      setAdminUserList(
        (Array.isArray(list) ? list : []).map(item => ({
          label: item.account,
          value: item.id,
        }))
      );
    } catch (err) {}
  };

  const getOrderId = async (amount, chainId) => {
    const url = `${EXAPAY_URL}/api/single-payment/create`;
    const response = await axios.post(url, {
      chainId: parseInt(chainId),
      token: EXAPAY_TOKEN,
      amount: amount,
      api_key: EXAPAY_API_KEY,
      receiver: {
        name: EXAPAY_RECEIVER_NAME,
        email: EXAPAY_RECEIVER_EMAIL,
      },
    });
    const orderId = response.data?.data?.id;
    return orderId;
  };

  const onSubmitButtonClick = async values => {
    console.log('[onSubmitButtonClick]', values);
    console.log('network list: ', networkList);
    const { allowedCountryCodes, ...otherValues } = values;
    const providerIds = (values.providerIds || []).map(n => n[1]);
    const orderId = await getOrderId(values.smsAmount, values.paymentNetwork);
    const body = {
      ...values,
      providerIds,
      id: updateId || '',
      allowedCountryCodes: JSON.stringify(allowedCountryCodes),
      orderId: orderId,
    };
    let apiName = isSuperAdmin
      ? `${updateId ? 'update' : 'create'}`
      : 'updateSmsAmount';

    try {
      setIsSubmiting(true);

      const { data } = await axios.post(
        `${API_BASE_URL}/octopus/api/admin/account/${apiName}`,
        body,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (data?.code === 200) {
        toast({
          title: 'Success!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setOpen(false);
        getDataSource();
        setTimeout(() => {
          form.resetFields();
        }, 500);
      } else {
        throw new Error(data?.message);
      }
      if (apiName === 'updateSmsAmount') {
        const chainId = values.paymentNetwork || '';
        const chainName =
          networkList.find(n => n.chainId === chainId)?.name || '';
        console.log('update sms quota');
        window.location.href = `/middle/order-confirmation?amount=${values.smsAmount}&chainId=${chainId}&chainName=${chainName}&orderId=${orderId}`;
      }
    } catch (error) {
      toast({
        title: error?.message || 'System Error',
        description: 'Try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      console.log('[onSubmitButtonClick Erro]', error);
    } finally {
      setIsSubmiting(false);
    }
  };

  const onEditButtonClick = e => {
    setUpdateId(e.id);
    console.log('[onEditButtonClick]', e, form);
    let allowedCountryCodes = [];
    try {
      allowedCountryCodes = JSON.parse(e.allowedCountryCodes || '');
      if (!Array.isArray(allowedCountryCodes)) {
        allowedCountryCodes = (`${e.allowedCountryCodes}` || '')
          .split(',')
          .map(code => ({
            price: '',
            code: code == null ? undefined : code,
            markup: '',
          }));
      }
    } catch (error) {
      allowedCountryCodes = (`${e.allowedCountryCodes}` || '')
        .split(',')
        .map(code => ({
          price: '',
          code: undefined,
          markup: '',
        }));
    }
    allowedCountryCodes = allowedCountryCodes.map(item => ({
      ...item,
      price: item.price || '',
    }));
    setAllowedCountryCodes(allowedCountryCodes);
    form.setFieldsValue({
      username: e.username,
      status: e.status,
      providerIds: (e.providers || []).map(item => [item.type, item.id]),
      canUseBy: (e.canUseBy || []).map(n => n.id),
      smsAmount: e.smsAmount,
      paymentNetwork: e.paymentNetwork,
      email: e.email,
      allowedCountryCodes,
    });
    setOpen(true);
  };

  const onCreateButtonClick = () => {
    setUpdateId('');
    form.resetFields();
    setOpen(true);
    setAllowedCountryCodes([]);
  };

  // const onAddAllowedCountryCode = e => {
  //   console.log('e', e);
  //   setAllowedCountryCodes([...allowedCountryCodes, { price: '', code: '' }]);

  //   console.log('new allowed: ', allowedCountryCodes);
  // };

  // const onReduceAllowedCountryCode = index => {
  //   console.log('old allowed: ', allowedCountryCodes);
  //   const newAllowedCountryCodes = allowedCountryCodes.filter(
  //     (_, i) => i !== index
  //   );
  //   form.setFieldValue('allowedCountryCodes', newAllowedCountryCodes);
  //   setAllowedCountryCodes(newAllowedCountryCodes);
  // };

  const onAddAllowedCountryCode = index => {
    console.log('added: ', allowedCountryCodes);
    setAllowedCountryCodes([
      ...allowedCountryCodes,
      { code: '', price: '', markup: '' },
    ]);
  };

  const onReduceAllowedCountryCode = index => {
    const updatedCountryCodes = allowedCountryCodes.filter(
      (_, idx) => idx !== index
    );
    console.log('updated: ', updatedCountryCodes);
    setAllowedCountryCodes(updatedCountryCodes);
  };

  const handleChange = (value, field, index) => {
    const updatedCountryCodes = [...allowedCountryCodes];
    updatedCountryCodes[index][field] = value;
    setAllowedCountryCodes(updatedCountryCodes);
    // handlePrice(value, index);
  };

  const handlePaymentNetwork = async () => {
    const result = await getNetworkList();
    let items = result.map(({ chainId, icon, name }) => {
      return {
        value: `${chainId}`,
        label: name,
      };
    });
    console.log(items);
    setNetworkList(result);
    setPaymentNetwork(items);
  };

  useEffect(() => {
    if (userInfo.roleType) {
      getDataSource();
      getProviderList();
      getAdminUserList();
      handlePaymentNetwork();
      getAllPrices();
    }
  }, [userInfo]);

  return (
    <>
      {userInfo.roleType === 'SUPER_ADMIN' ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <ChakraButton
            colorScheme="purple"
            type="submit"
            mt="24"
            mb="12"
            onClick={onCreateButtonClick}
          >
            Create Account
          </ChakraButton>
        </div>
      ) : null}

      <Table
        dataSource={dataSource}
        columns={columns}
        loading={loading}
        pagination={{ total }}
      />
      <Modal
        open={open}
        title={
          isSuperAdmin
            ? 'Edit SMS Quota'
            : updateId
            ? 'Edit Account'
            : 'Create Account'
        }
        okText="Submit"
        cancelText="Cancel"
        confirmLoading={isSubmiting}
        onCancel={() => setOpen(false)}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              onSubmitButtonClick(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ modifier: 'public' }}
        >
          {isSuperAdmin ? (
            <>
              <Form.Item
                name="username"
                label="username"
                rules={[
                  {
                    required: true,
                    message: 'Please input the username of collection!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              {!updateId ? (
                <>
                  <Form.Item
                    name="password"
                    label="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input the password of collection!',
                      },
                    ]}
                  >
                    <Input disabled={!!updateId} />
                  </Form.Item>
                </>
              ) : null}

              <Form.Item
                name="providerIds"
                label="providerIds"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Cascader
                  options={providerList}
                  placeholder="Please select"
                  multiple
                  showCheckedStrategy={Cascader.SHOW_CHILD}
                />
              </Form.Item>
            </>
          ) : null}

          <Form.Item
            name="smsAmount"
            label="smsAmount"
            rules={[
              {
                required: true,
                message: 'Please input the smsAmount of collection!',
              },
            ]}
          >
            <Input placeholder="0" addonAfter="$USD" type="number" />
          </Form.Item>
          <Form.Item
            name="paymentNetwork"
            label="Payment Network"
            rules={[
              {
                required: true,
                message: 'Please input the paymentNetwork of collection!',
              },
            ]}
          >
            <Select
              placeholder={'Please select'}
              options={paymentNetwork}
              mode={'single'}
            />
          </Form.Item>
          {allowedCountryCodes.map((item, index) => (
            <div style={{ display: 'flex', alignItems: 'center' }} key={index}>
              <Form.Item
                name={['allowedCountryCodes', index, 'code']}
                label="Allowed Countries"
                rules={[{ required: true, message: 'Please select!' }]}
              >
                <Select
                  showSearch
                  placeholder="Please select"
                  options={CountryCodes}
                  onSelect={value => {
                    handlePrice(value, index);
                    handleChange(value, 'code', index);
                  }}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  value={item.code}
                />
              </Form.Item>
              <Form.Item
                name={['allowedCountryCodes', index, 'price']}
                label="Price"
                style={{ marginLeft: '10px', width: '20%' }}
              >
                <Input
                  placeholder="Please input price"
                  type="number"
                  readOnly
                  value={item.price || ''}
                />
              </Form.Item>
              <Form.Item
                name={['allowedCountryCodes', index, 'markup']}
                label="Markup"
                rules={[{ required: true, message: 'Input markup!' }]}
                style={{ marginLeft: '10px', width: '30%' }}
              >
                <Input
                  placeholder="Please input markup ratio"
                  type="number"
                  addonAfter="%"
                  value={item.markup || ''}
                  onChange={e => handleChange(e.target.value, 'markup', index)}
                />
              </Form.Item>
              {index === allowedCountryCodes.length - 1 &&
                allowedCountryCodes.length > 1 && (
                  <Button
                    style={{ margin: '4px 0 0 4px' }}
                    type="primary"
                    shape="circle"
                    onClick={() => onReduceAllowedCountryCode(index)}
                    icon={<DeleteOutlined />}
                  />
                )}
              {index === allowedCountryCodes.length - 1 && (
                <Button
                  style={{ margin: '4px 0 0 4px' }}
                  type="primary"
                  shape="circle"
                  onClick={onAddAllowedCountryCode}
                  icon={<PlusCircleOutlined />}
                />
              )}
            </div>
          ))}
          {/* {allowedCountryCodes.map((item, index) => (
            <div style={{ display: 'flex', alignItems: 'center' }} key={index}>
              <Form.Item
                name={['allowedCountryCodes', index, 'code']}
                label="Allowed Countries"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder={'Please select'}
                  options={CountryCodes}
                  onSelect={e => handlePrice(e, index)}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>
              <Form.Item
                name={['allowedCountryCodes', index, 'price']}
                label="Price"
                rules={[{ required: true }]}
                style={{ marginLeft: '10px' }}
              >
                <Input
                  placeholder="Place input markup ration"
                  type="number"
                  readOnly
                  addonAfter="%"
                />
              </Form.Item>
              <Form.Item
                name={['allowedCountryCodes', index, 'markup']}
                label="Markup"
                rules={[{ required: true }]}
                style={{ marginLeft: '10px' }}
              >
                <Input
                  placeholder="Place input markup ration"
                  type="number"
                  addonAfter="%"
                />
              </Form.Item>
              <Button
                style={{ margin: '4px 0 0 4px' }}
                type="primary"
                shape="circle"
                onClick={() => onReduceAllowedCountryCode(index)}
                icon={<DeleteOutlined />}
              />
              {index === allowedCountryCodes.length - 1 ? (
                <Button
                  style={{ margin: '4px 0 0 4px' }}
                  type="primary"
                  shape="circle"
                  onClick={() => onAddAllowedCountryCode(index)}
                  icon={<PlusCircleOutlined />}
                />
              ) : null}
            </div>
          ))} */}

          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: 'Please input the email',
              },
              {
                type: 'email',
                message: 'Please enter a valid email address',
              },
            ]}
          >
            <Input placeholder={'Please input'} type="string" />
          </Form.Item>
          {isSuperAdmin ? (
            <>
              <Form.Item
                name="status"
                label="Status"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder={'Please select'}
                  options={[
                    { value: 'NORMAL', label: 'NORMAL' },
                    { value: 'DISABLED', label: 'DISABLED' },
                  ]}
                />
              </Form.Item>
              <Form.Item
                name="canUseBy"
                label="CanUseBy Admin User"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder={'Please select'}
                  options={adminUserList}
                  mode={'multiple'}
                />
              </Form.Item>
            </>
          ) : null}
        </Form>
      </Modal>
    </>
  );
}

export default ViewQuota;
