import {
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  Flex,
  Input,
  Stack,
  Textarea,
  useToast,
  Box,
  Grid,
  Text,
  GridItem,
  useBreakpoint,
  Radio,
  RadioGroup,
} from '@chakra-ui/react';
import { Space, Table, Card, Row, Col } from 'antd';
import { useRef, useContext } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { useForm } from 'react-hook-form';
import { API_BASE_URL } from '../config';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import EmulatorOutline from '../emulator/EmulatorOutline';
import { AuthContext } from '../../contexts/index.tsx';
import SetVariables from './SetVariables';
import TopUpButton from '../button/TopUpButton';
import {
  isValidPhoneNumber,
  parsePhoneNumberFromString,
} from 'libphonenumber-js';
import { PhoneNumberUtil } from 'google-libphonenumber';

export default function SendSms({ props, variable1, variable2, variable3 }) {
  const { t } = useTranslation();
  const formRef = useRef(null);
  const { userInfo } = useContext(AuthContext);
  const [characterCount, setCharacterCount] = useState(0);
  const [messageContent, setMessageContent] = useState(null);
  const [splitMessages, setSplitMessages] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [variableType, setVariableType] = useState('TEST');
  const [accountList, setAccountList] = useState([]);
  const [destination, setDestination] = useState('');
  const [smsCost, setSmsCost] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const [submit, setSubmit] = useState(false);
  const [smsRemaining, setSmsRemaining] = useState(0);
  const [smsUsed, setSmsUsed] = useState(0);
  const [totalSmsMonth, setTotalSmsMonth] = useState(0);
  const [totalSpendingMonth, setTotalSpendingMonth] = useState(0);

  const [dataForm, setDataForm] = useState({});
  const [uploadedDestinations, setUploadedDestinations] = useState([]);
  const token = localStorage.getItem('token');
  const toast = useToast();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    trigger,
  } = useForm();

  const phoneUtil = PhoneNumberUtil.getInstance();

  const columns = [
    {
      title: 'Destination',
      dataIndex: 'destination',
      key: 'destination',
    },
    {
      title: 'Price per message',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Number of messages',
      dataIndex: 'count',
      key: 'count',
    },
  ];

  const splitMessagesIntoChunks = content => {
    const maxLength = 140;
    const messages = [];
    while (content.length > maxLength) {
      let sliceIndex = maxLength;
      while (content[sliceIndex] !== ' ' && sliceIndex > 0) {
        sliceIndex--;
      }
      if (sliceIndex == 0) {
        sliceIndex = maxLength;
      }
      messages.push(content.slice(0, sliceIndex).trim());
      content = content.slice(sliceIndex).trim();
    }
    if (content.length > 0) {
      messages.push(content);
    }
    return messages;
  };

  const validatePhoneNumber = phoneNumber => {
    const phoneNumberObj = parsePhoneNumberFromString(`+${phoneNumber}`);
    const countryCode = phoneNumberObj?.country;
    const numberWithoutCallingCode = phoneNumberObj?.nationalNumber;
    const number = phoneUtil.parseAndKeepRawInput(
      numberWithoutCallingCode,
      countryCode
    );
    const isValidNumber = phoneUtil.isValidNumber(number);
    return isValidNumber;
  };

  const onSend = async values => {
    let messages = [values.content];
    // if (values.content.length > 140) {
    //   messages = [values.content.slice(0, 140), values.content.slice(140)];
    //   setShowNotification(true);
    // }

    if (values.content.length > 140) {
      messages = splitMessagesIntoChunks(values.content);
      // setSplitMessages(messages);
      setShowNotification(true);
    }
    const destinations = (values.destination || '').split(',').filter(n => n);
    // Validate each phone number
    const invalidDestinations = destinations.filter(
      destination => !validatePhoneNumber(destination)
    );

    if (invalidDestinations.length > 0) {
      toast({
        title: 'Invalid phone numbers',
        description: `The following phone numbers are invalid: ${invalidDestinations.join(
          ', '
        )}`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      for (const message of messages) {
        console.log('message: ', message);

        const { data } = await axios.post(
          `${API_BASE_URL}/octopus/api/admin/message/batchSendMessage`,
          {
            source: values.source,
            destinations: (values.destination || '').split(',').filter(n => n),
            content: message,
            provider: values.provider,
            accountId:
              userInfo.roleType === 'SUPER_ADMIN'
                ? values.accountId
                : accountList.find(user => user.username === userInfo.account)
                    .id,
            // userInfo.account,
            // accountId: values.accountId,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if (data?.code === 200) {
          toast({
            title: 'Success!',
            description: 'Message sent!',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        } else {
          throw new Error(data?.message);
        }
        getSmsInfo();
        getMonthlySmsInfo();
      }
    } catch (error) {
      toast({
        title: error?.message || 'System Error',
        description: 'Try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    return;
  };

  // const handleBatchSend = async () => {
  //   try {
  //     const { data } = await axios.post(
  //       `${API_BASE_URL}/octopus/api/admin/message/batchSendMessage`,
  //       {
  //         source: dataForm.source,
  //         destinations: (dataForm.destination || '').split(',').filter(n => n),
  //         content: dataForm.content,
  //         provider: dataForm.provider,
  //         accountId:
  //           userInfo.roleType === 'SUPER_ADMIN'
  //             ? dataForm.accountId
  //             : accountList.find(user => user.username === userInfo.account).id,
  //       },
  //       { headers: { Authorization: `Bearer ${token}` } }
  //     );
  //     if (data?.code === 200) {
  //       toast({
  //         title: 'Success!',
  //         description: 'Message sent!',
  //         status: 'success',
  //         duration: 3000,
  //         isClosable: true,
  //       });
  //     } else {
  //       throw new Error(data?.message);
  //     }
  //   } catch (error) {
  //     toast({
  //       title: error?.message || 'System Error',
  //       description: 'Try again.',
  //       status: 'error',
  //       duration: 3000,
  //       isClosable: true,
  //     });
  //   }
  // };

  const handleFileUpload = e => {
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    // reader.onload = e => {
    //   const data = e.target.result;
    //   console.log('data: ', data);
    //   const workbook = XLSX.read(data, { type: 'array' });
    //   const firstSheetName = workbook.SheetNames[0];
    //   const worksheet = workbook.Sheets[firstSheetName];
    //   const results = XLSX.utils.sheet_to_json(worksheet);
    //   console.log('results: ', results);
    //   // formRef.current.destination.value = results
    //   //   .map(item => `${item.phone}`)
    //   //   .filter(n => n);
    //   const destinations = results
    //     .map(item => {
    //       console.log('item: ', item);
    //     })
    //     .filter(n => n);
    //   formRef.current.destination.value = destinations.join(',');
    //   formRef.current.destination.focus();
    //   setUploadedDestinations(destinations);
    // };
    // reader.readAsArrayBuffer(file);
    reader.onload = e => {
      const data = e.target.result;
      console.log('data: ', data);
      const phoneNumbers = data.split(',').map(number => number.trim());
      console.log('phoneNumbers: ', phoneNumbers);

      formRef.current.destination.value = phoneNumbers.join(',');
      formRef.current.destination.focus();
      setUploadedDestinations(phoneNumbers);
      setValue('destination', phoneNumbers.join(','));
      trigger('destination');
    };
    reader.readAsText(file);
  };

  const queryAccountList = async () => {
    try {
      const { data } = await axios.get(
        `${API_BASE_URL}/octopus/api/admin/account/list`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const list = data?.data?.list;
      console.log('account list: ', list);
      setAccountList(Array.isArray(list) ? list : []);
    } catch (err) {
      console.log('[queryAccountList Error]', err);
    }
  };

  const getSmsInfo = async () => {
    try {
      const { data } = await axios.get(
        `${API_BASE_URL}/octopus/api/admin/sms/info`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSmsRemaining(data?.data?.smsRemaining || 0);
      setSmsUsed(data?.data?.smsUsed || 0);
    } catch (err) {
      console.log(err);
    }
  };

  const getMonthlySmsInfo = async () => {
    try {
      const { data } = await axios.get(
        `${API_BASE_URL}/octopus/api/admin/sms/monthly`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setTotalSmsMonth(data?.data?.totalMessages || 0);
      setTotalSpendingMonth(data?.data?.totalPrice || 0);
      // setSmsInfo(data?.data || []);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAccountSelect = async () => {
    let form = new FormData(formRef.current);
    const formData = Object.fromEntries(form.entries());
    try {
      const { data } = await axios.post(
        `${API_BASE_URL}/octopus/api/admin/sms/cost`,
        {
          destinations: (formData.destination || '').split(',').filter(n => n),
          accountId:
            userInfo.roleType === 'SUPER_ADMIN' || accountList.length > 1
              ? formData.accountId
              : accountList.find(user => user.username === userInfo.account).id,
          // accountId: formData.accountId,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const breakdownWithCount = (data?.data?.breakdown || []).map(item => ({
        ...item,
        count: splitMessages.length,
      }));
      setSmsCost(breakdownWithCount);
      const totalCost = breakdownWithCount.reduce(
        (p, v) => p + v.price * v.count,
        0
      );
      setTotalCost(parseFloat(totalCost).toFixed(3));
    } catch (err) {
      console.log(err);
    }
    return;
  };

  const handleOnChangeMessage = async e => {
    setCharacterCount(e.target.value.length);
    setMessageContent(e.target.value);
    if (e.target.value.length > 140) {
      // setSplitMessages([
      //   e.target.value.slice(0, 140),
      //   e.target.value.slice(140),
      // ]);
      const messages = splitMessagesIntoChunks(e.target.value);
      setSplitMessages(messages);
    } else {
      setSplitMessages([e.target.value]);
    }
  };

  const getDataSource = async (payload = {}) => {
    const current = payload.current;
    const pageSize = payload.pageSize;
    try {
      // setLoading(true);
      const { data } = await axios.post(
        `${API_BASE_URL}/octopus/api/admin/recharge-record/list`,
        {
          pageNo: current,
          pageSize: pageSize,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const list = data?.data?.list;
      const retrievedData = Array.isArray(list) ? list : [];
      const updatedData = retrievedData.map((item, index) => ({
        ...item,
        updatedId: index + 1 + (current - 1) * pageSize,
      }));
      // setDataSource(updatedData);
      // setTotal(data?.data?.total || 0);

      // // check if there is any entry with a status other than success
      // setShowRefreshButton(list.some(n => n.orderStatus !== 'Success'));
    } catch (err) {
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    queryAccountList();
    getSmsInfo();
    getMonthlySmsInfo();
    getDataSource();
    handleAccountSelect();
  }, []);

  return (
    <>
      {userInfo.roleType === 'SUPER_ADMIN' ? (
        <></>
      ) : (
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            lg: 'repeat(4,1fr)',
          }}
          gap={10}
          mb={10}
        >
          <GridItem>
            <Card
              hoverable={true}
              style={{
                backgroundColor: '#805AD5',
                color: 'white',
                height: '100%',
              }}
            >
              <Box>{t('amountRemaining')}</Box>
              <Box fontSize={'4xl'} fontWeight="bold">
                {smsRemaining}
              </Box>
              {/* <Button
                style={{
                  backgroundColor: '#805AD5',
                  borderColor: 'white',
                  borderWidth: 2,
                  color: 'white',
                  position: 'absolute',
                  right: 0,
                  bottom: 0,
                  margin: 25,
                }}
              >
                Top up <TopUpButton />
              </Button> */}
              <Box
                style={{
                  backgroundColor: '#805AD5',
                  borderColor: 'white',
                  borderWidth: 2,
                  color: 'white',
                  position: 'absolute',
                  right: 0,
                  bottom: 0,
                  margin: 25,
                }}
              >
                <TopUpButton />
                {/* {console.log('account list: ', accountList)}
                {accountList !== 'undefined' && userInfo !== 'undefined' ? (
                  <>
                    <TopUpButton
                    // accountId={
                    //   accountList.find(
                    //     user => user.username === userInfo.account
                    //   ).id
                    // }
                    />
                  </>
                ) : (
                )} */}
              </Box>
            </Card>
          </GridItem>
          <GridItem>
            <Card
              hoverable={true}
              style={{
                backgroundColor: '#805AD5',
                color: 'white',
                height: '100%',
              }}
            >
              <Box>{t('amountUsed')}</Box>
              <Box fontSize={'4xl'} fontWeight="bold">
                {smsUsed}
              </Box>
            </Card>
          </GridItem>

          <GridItem>
            <Card
              hoverable={true}
              style={{
                backgroundColor: '#805AD5',
                color: 'white',
                marginBottom: 10,
              }}
            >
              <Box>{t('smsSent')}</Box>
              <Box fontSize={'2xl'} fontWeight="bold">
                {totalSmsMonth}
              </Box>
            </Card>
            <Card
              hoverable={true}
              style={{ backgroundColor: '#805AD5', color: 'white' }}
            >
              <Box>{t('spending')}</Box>
              <Box fontSize={'2xl'} fontWeight="bold">
                {totalSpendingMonth}
              </Box>
            </Card>
          </GridItem>
        </Grid>
      )}
      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          lg: 'repeat(2,1fr)',
        }}
        gap={10}
      >
        <GridItem>
          <form onSubmit={handleSubmit(onSend)} ref={formRef}>
            <Stack
              spacing={3}
              display={{ md: 'flex' }}
              justifyContent={'space-between'}
            >
              <FormControl isInvalid={errors['source']}>
                <FormLabel>{t('form.origin')}</FormLabel>
                <Input
                  type="tel"
                  placeholder={t('form.phone')}
                  {...register('source', { required: true })}
                />
                <FormErrorMessage>
                  {errors['source'] && 'Origin is required'}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.destination} marginBottom={4}>
                <FormLabel htmlFor="destination">Destination</FormLabel>
                <Textarea
                  id="destination"
                  placeholder="Destination"
                  {...register('destination', {
                    required: 'This is required',
                  })}
                  onBlur={handleAccountSelect}
                />
                <FormErrorMessage>
                  {errors.destination && errors.destination.message}
                </FormErrorMessage>
              </FormControl>
              <Input type="file" onChange={handleFileUpload} />

              {/* <FormControl isInvalid={errors['destination']}>
                <FormLabel>{t('form.destination')}</FormLabel>
                <Box>
                  <Textarea
                    type="tel"
                    placeholder={t('form.phone')}
                    {...register('destination', { required: true })}
                    defaultValue={''}
                    onChange={e => {
                      handleAccountSelect();
                    }}
                  />
                  <Input
                    style={{ marginTop: '8px' }}
                    type="file"
                    placeholder="upload a xlsx file, the first row is 'Phone'"
                    variant="unstyled"
                    onChange={handleFileUpload}
                  />
                </Box>
                <FormErrorMessage>
                  {errors['destinationFromType'] && 'Destination is required'}
                </FormErrorMessage>
              </FormControl> */}
              <FormControl isInvalid={errors['content']}>
                <FormLabel>{t('form.message')}</FormLabel>
                <Stack>
                  <Textarea
                    placeholder={t('form.write')}
                    {...register('content', { required: true })}
                    onChange={e => {
                      handleOnChangeMessage(e);
                      handleAccountSelect(e);
                    }}
                    onPaste={e => {
                      handleOnChangeMessage(e);
                      handleAccountSelect(e);
                    }}
                    onMouseMove={e => {
                      handleOnChangeMessage(e);
                      handleAccountSelect(e);
                    }}
                    onMouseLeave={e => {
                      handleOnChangeMessage(e);
                      handleAccountSelect(e);
                    }}
                    paddingRight="70px"
                  ></Textarea>
                  <Box
                    position="absolute"
                    float="right"
                    right="5"
                    bottom="0"
                    paddingTop="20px"
                  >
                    {characterCount}
                  </Box>
                </Stack>
                <FormErrorMessage>
                  {errors['content'] &&
                    errors['content'].type === 'required' &&
                    'Message is required'}
                </FormErrorMessage>
              </FormControl>
              {userInfo.roleType === 'SUPER_ADMIN' || accountList.length > 1 ? (
                <FormControl isInvalid={errors['accountId']}>
                  <FormLabel>{t('account')}</FormLabel>
                  <Select
                    placeholder={'Select a account'}
                    {...register('accountId', { required: true })}
                    onChange={handleAccountSelect}
                  >
                    {accountList.map(item => (
                      <option value={item.id} key={item.id}>
                        {item.username}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>
                    {errors.provider && 'Account is required'}
                  </FormErrorMessage>
                </FormControl>
              ) : (
                <></>
              )}

              {smsCost.length !== 0 ? (
                <Table
                  dataSource={smsCost}
                  columns={columns}
                  pagination={false}
                  summary={pageData => {
                    return (
                      <>
                        <Table.Summary.Row style={{ fontWeight: 'bold' }}>
                          <Table.Summary.Cell>
                            {t('form.total')}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>{totalCost}</Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                ></Table>
              ) : (
                <></>
              )}
              <Button colorScheme="purple" type="submit">
                {t('form.submit')}
              </Button>
            </Stack>
          </form>
          {showNotification && (
            <Box mt={5}>
              <Text color={'red.500'}>
                your message is being sent as multiple messages!
              </Text>
            </Box>
          )}
        </GridItem>
        <GridItem
          // width={{ lg: '80%', base: '50%' }}
          mt={{ base: '30px', lg: '0px' }}
        >
          <EmulatorOutline
            message={splitMessages}
            characterCount={characterCount}
            variableType={variableType}
          />
        </GridItem>
      </Grid>
    </>
  );
}
