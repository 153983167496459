import { useTranslation } from 'react-i18next';
import { Table, Button, Form, Input, Space, Alert } from 'antd';
import { Button as ChakraButton, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Import this to use the autoTable plugin

function ViewQuota() {
  const { t } = useTranslation();
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState([]);
  const toast = useToast();
  const [showRefreshButton, setShowRefreshButton] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  const columns = [
    {
      title: 'id',
      dataIndex: 'updatedId',
    },
    {
      title: 'content',
      dataIndex: 'content',
      width: '400px',
    },
    {
      title: 'Recharge Amount (USD)',
      dataIndex: 'smsCount',
    },
    {
      title: 'account',
      dataIndex: 'account',
    },
    {
      title: 'order status',
      dataIndex: 'orderStatus',
    },
    {
      title: 'order id',
      dataIndex: 'orderId',
    },
    {
      title: 'createBy',
      dataIndex: 'createBy',
    },
    {
      title: 'createdAt',
      dataIndex: 'createdAt',
    },
    {
      title: 'Download Invoice',
      render: (_, record) => (
        <Button onClick={() => downloadInvoice(record)}>Download</Button>
      ),
    },
  ];

  const downloadInvoice = record => {
    const doc = new jsPDF();
    // Document header
    doc.setFontSize(18);
    doc.text('Invoice', 105, 20, { align: 'center' });

    // Adding a line under the header
    doc.setDrawColor(0);
    doc.setLineWidth(1);
    doc.line(10, 25, 200, 25);

    // Customer Details
    doc.setFontSize(12);
    doc.text(`Order ID: ${record.orderId}`, 10, 35);
    doc.text(`Created At: ${record.createdAt}`, 10, 45);
    doc.text(`Account: ${record.account}`, 10, 55);

    // Invoice Table
    const tableColumn = ['ID', 'Content', 'Recharge Amount', 'Order Status'];
    const tableRows = [
      [record.id, record.content, record.smsCount, record.orderStatus],
    ];

    // Start Y position for the table
    doc.autoTable(tableColumn, tableRows, { startY: 65 });

    // Document Footer
    doc.setFontSize(10);
    doc.text('Thank you for your business!', 105, 280, { align: 'center' });

    // Saving the document
    doc.save(`invoice-${record.id}.pdf`);
    // const csvContent =
    //   `ID,Content,SMS Count,Account,Order Status,Order ID,Created By,Created At\n` +
    //   `${record.id},${record.content},${record.smsCount},${record.account},${record.orderStatus},${record.orderId},${record.createBy},${record.createdAt}`;

    // const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    // saveAs(blob, `invoice-${record.id}.csv`);
  };

  const getDataSource = async (payload = {}) => {
    const current = payload.current || pagination.current;
    const pageSize = payload.pageSize || pagination.pageSize;
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${API_BASE_URL}/octopus/api/admin/recharge-record/list`,
        {
          pageNo: current,
          pageSize: pageSize,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const list = data?.data?.list;
      console.log(list);
      const retrievedData = Array.isArray(list) ? list : [];
      const updatedData = retrievedData.map((item, index) => ({
        ...item,
        updatedId: index + 1 + (current - 1) * pageSize,
      }));
      setDataSource(updatedData);
      setTotal(data?.data?.total || 0);

      // check if there is any entry with a status other than success
      setShowRefreshButton(list.some(n => n.orderStatus !== 'Success'));
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  // const handlePendingPayments = async (payload = {}) => {
  //   try {
  //     const { data } = await axios.post(
  //       `${API_BASE_URL}/octopus/api/admin/recharge-record/updateTopup`,
  //       {
  //         pageNo: payload.current || 1,
  //         pageSize: payload.pageSize || 10,
  //       },
  //       {
  //         headers: { Authorization: `Bearer ${token}` },
  //       }
  //     );
  //     console.log('update topup: ', data);
  //   } catch (err) {}
  // };

  const onTableChange = pagination => {
    setPagination(pagination);
    getDataSource(pagination);
    // handlePendingPayments(e);
  };

  useEffect(() => {
    getDataSource(pagination);
    // handlePendingPayments();
  }, [pagination]);

  return (
    <div style={{ overflowX: 'scroll', width: '1300px', height: '100%' }}>
      {showRefreshButton && (
        <>
          {' '}
          {/* <Alert
            message="Disclaimer"
            description="Please refresh the data to update the order status."
            type="warning"
            showIcon
            style={{ marginBottom: '20px' }}
          /> */}
          <Button
            onClick={() => getDataSource()}
            style={{ marginBottom: '20px' }}
          >
            Refresh data
          </Button>
        </>
      )}
      <Table
        dataSource={dataSource}
        loading={loading}
        columns={columns}
        pagination={{
          total,
          current: pagination.current,
          pageSize: pagination.pageSize,
        }}
        onChange={onTableChange}
      />
    </div>
  );
}

export default ViewQuota;
