import { useTranslation } from 'react-i18next';
import { Button as ChakraButton, useToast } from '@chakra-ui/react';
import { Space, Table, Tag, Form, Input, Modal, Cascader, Select } from 'antd';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

function ViewQuota() {
  const toast = useToast();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateId, setUpdateId] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [total, setTotal] = useState([]);

  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
    },
    {
      title: 'roleType',
      dataIndex: 'roleType',
    },
    {
      title: 'account',
      dataIndex: 'account',
    },
    {
      title: 'createdAt',
      dataIndex: 'createdAt',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <ChakraButton
            colorScheme="purple"
            type="submit"
            onClick={() => onEditButtonClick(record)}
          >
            Edit
          </ChakraButton>
        </Space>
      ),
    },
  ];

  const getDataSource = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${API_BASE_URL}/octopus/api/admin/user/list`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const list = data?.data?.list;
      setDataSource(Array.isArray(list) ? list : []);
      console.log('data source: ', list);
      setTotal(data?.data?.total || 0);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const onSubmitButtonClick = async values => {
    console.log('[onSubmitButtonClick]', values);
    const body = {
      ...values,
      id: updateId || '',
    };
    try {
      setIsSubmiting(true);
      const { data } = await axios.post(
        `${API_BASE_URL}/octopus/api/admin/user/${
          updateId ? 'update' : 'create'
        }`,
        body,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (data?.code === 200) {
        toast({
          title: 'Success!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setOpen(false);
        getDataSource();
        setTimeout(() => {
          form.resetFields();
        }, 500);
      } else {
        throw new Error(data?.message);
      }
    } catch (error) {
      toast({
        title: error?.message || 'System Error',
        description: 'Try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      console.log('[onSubmitButtonClick Erro]', error);
    } finally {
      setIsSubmiting(false);
    }
  };

  const onEditButtonClick = e => {
    setIsEdit(true);
    setUpdateId(e.id);
    console.log('[onEditButtonClick]', e, form);
    form.setFieldsValue({
      account: e.account,
      password: '',
    });
    setOpen(true);
  };

  const onCreateButtonClick = () => {
    setUpdateId('');
    form.resetFields();
    setOpen(true);
  };

  useEffect(() => {
    getDataSource();
  }, []);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <ChakraButton
          colorScheme="purple"
          type="submit"
          mt="24"
          mb="12"
          onClick={onCreateButtonClick}
        >
          Create Admin User
        </ChakraButton>
      </div>
      <Table
        dataSource={dataSource}
        loading={loading}
        columns={columns}
        pagination={{ total }}
      />
      <Modal
        open={open}
        title={isEdit ? 'Edit Admin User' : 'Create Admin User'}
        okText="Submit"
        cancelText="Cancel"
        confirmLoading={isSubmiting}
        onCancel={() => setOpen(false)}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              onSubmitButtonClick(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ modifier: 'public' }}
        >
          <Form.Item
            name="account"
            label="account"
            rules={[
              {
                required: true,
                message: 'Please input the account of collection!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          {isEdit ? (
            <>
              {' '}
              <Form.Item
                name="password"
                label="change password (if password is blank, password won't be changed)"
              >
                <Input />
              </Form.Item>
            </>
          ) : (
            <>
              {' '}
              <Form.Item
                name="password"
                label="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input a password!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    </>
  );
}

export default ViewQuota;
