import { useTranslation } from 'react-i18next';
import { Button as ChakraButton, useToast } from '@chakra-ui/react';
import {
  Space,
  Table,
  Form,
  Input,
  Modal,
  Cascader,
  Select,
  InputNumber,
  Tooltip,
  Typography,
} from 'antd';
import axios from 'axios';
import {
  API_BASE_URL,
  EXAPAY_URL,
  EXAPAY_API_KEY,
  EXAPAY_RECEIVER_EMAIL,
  EXAPAY_RECEIVER_NAME,
  EXAPAY_TOKEN,
} from '../config';
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts/index.tsx';
import { getNetworkList } from '../exapay/GetNetworkList';

const { Text } = Typography;

function TopUpButton(props = {}) {
  // const { accountId } = props || {};
  const { userInfo } = useContext(AuthContext);
  const toast = useToast();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const token = localStorage.getItem('token');
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [open, setOpen] = useState(false);
  const [networkList, setNetworkList] = useState([]);
  const [paymentNetwork, setPaymentNetwork] = useState([]);
  const [accountList, setAccountList] = useState([]);

  const getOrderId = async (amount, chainId) => {
    const url = `${EXAPAY_URL}/api/single-payment/create`;
    const response = await axios.post(url, {
      chainId: parseInt(chainId),
      token: EXAPAY_TOKEN,
      amount: amount,
      api_key: EXAPAY_API_KEY,
      receiver: {
        name: EXAPAY_RECEIVER_NAME,
        email: EXAPAY_RECEIVER_EMAIL,
      },
    });
    const orderId = response.data?.data?.id;
    return orderId;
  };

  const queryAccountList = async () => {
    try {
      const { data } = await axios.get(
        `${API_BASE_URL}/octopus/api/admin/account/list`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const list = data?.data?.list;
      console.log('lsit: ', list);
      setAccountList(Array.isArray(list) ? list : []);
    } catch (err) {
      console.log('[queryAccountList Error]', err);
    }
  };

  const onSubmitButtonClick = async e => {
    console.log('[onSubmitButtonClick]', e);
    console.log('network list: ', networkList);
    const orderId = await getOrderId(e.smsAmount, e.paymentNetwork);
    const body = {
      ...e,
      id: accountList.find(user => user.username === userInfo.account).id,
      orderId: orderId,
    };

    try {
      setIsSubmiting(true);

      const { data } = await axios.post(
        `${API_BASE_URL}/octopus/api/admin/account/updateSmsAmount`,
        body,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (data?.code === 200) {
        toast({
          title: 'Success!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        console.log('data: ', data);
        setOpen(false);
        setTimeout(() => {
          form.resetFields();
        }, 500);
      } else {
        throw new Error(data?.message);
      }
      const chainId = e.paymentNetwork || '';
      const chainName = networkList.find(n => n.chainId == chainId)?.name || '';
      console.log('update sms quota');
      window.location.href = `/middle/order-confirmation?amount=${e.smsAmount}&chainId=${chainId}&chainName=${chainName}&orderId=${orderId}`;
    } catch (error) {
      toast({
        title: error?.message || 'System Error',
        description: 'Try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      console.log('[onSubmitButtonClick Erro]', error);
    } finally {
      setIsSubmiting(false);
    }
  };

  const handlePaymentNetwork = async () => {
    const result = await getNetworkList();
    let items = result.map(({ chainId, icon, name }) => {
      return {
        value: `${chainId}`,
        label: name,
      };
    });
    console.log(items);
    setNetworkList(result);
    setPaymentNetwork(items);
  };

  const onCreateButtonClick = () => {
    console.log('userinfo: ', userInfo);
    // setUpdateId(userInfo.id);
    setOpen(true);
  };

  const handleChange = value => {
    if (value !== null && value !== undefined) {
      // Round the value to two decimal places
      // const roundedValue = Math.ceil(value * 100) / 100;
      form.setFieldsValue({ smsAmount: value });
    }
  };

  const validateDecimals = (rule, value) => {
    if (value !== undefined && !/^\d+(\.\d{1,2})?$/.test(value)) {
      return Promise.reject(
        'Please enter a valid amount (above 0) with up to 2 decimal places.'
      );
    }
    return Promise.resolve();
  };

  useEffect(() => {
    queryAccountList();
    if (userInfo.roleType) {
      handlePaymentNetwork();
    }
  }, [userInfo]);

  useEffect(() => {
    queryAccountList();
    if (userInfo.roleType) {
      handlePaymentNetwork();
    }
  }, [userInfo]);

  return (
    <>
      <ChakraButton
        colorScheme="purple"
        type="submit"
        onClick={onCreateButtonClick}
      >
        Topup SMS Balance
      </ChakraButton>
      <Modal
        open={open}
        title="Topup SMS balance"
        okText="Submit"
        cancelText="Cancel"
        confirmLoading={isSubmiting}
        onCancel={() => setOpen(false)}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              onSubmitButtonClick(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ modifier: 'public' }}
        >
          <Form.Item
            name="smsAmount"
            label="amount"
            rules={[
              {
                required: true,
                message: 'Please input the smsAmount of collection!',
              },
            ]}
          >
            <InputNumber
              placeholder="0"
              addonAfter="$USD"
              type="number"
              min={0}
              step={0.01}
              precision={2}
              onChange={handleChange}
              // formatter={value =>
              //   `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              // }
              // parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
            />
            <div style={{ marginTop: 4 }}>
              <Tooltip>
                <Text type="secondary">
                  Only values up to 2 decimal places are accepted.
                </Text>
              </Tooltip>
            </div>
          </Form.Item>
          <Form.Item
            name="paymentNetwork"
            label="Payment Network"
            rules={[
              {
                required: true,
                message: 'Please input the paymentNetwork of collection!',
              },
            ]}
          >
            <Select
              placeholder={'Please select'}
              options={paymentNetwork}
              mode={'single'}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default TopUpButton;
